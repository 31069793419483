
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/filter/filter.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set-value.js';
import '@brightspace-ui/core/components/inputs/input-date-time-range.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/dialog/dialog-fullscreen.js';
import '@brightspace-ui/core/components/inputs/input-date-range.js';
import '@brightspace-ui/core/components/dropdown/dropdown-button-subtle.js';
import '@brightspace-ui/core/components/dropdown/dropdown-content.js';

import '../report-applied-filter-options/report-applied-filter-options.js';

import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SUBMIT_MY_OWN_PROVIDER_ID } from '../../../../../shared/constants.js';

import { formatIsoDate } from '../../../../../shared/helpers/dateTime.js';
import { LocalizeNova } from '../../../mixins/localize-nova/localize-nova.js';
import ReportDashboardFilterModel from '../../../models/report-dashboard-filter/report-dashboard-filter.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

export default class ReportDashboardFilter extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      isAdmin: { type: Boolean, reflect: false, attribute: 'is-admin' },
      isProvider: { type: Boolean, reflect: false, attribute: 'is-provider' },
      isSponsor: { type: Boolean, reflect: false, attribute: 'is-sponsor' },
      isWatcher: { type: Boolean, reflect: false, attribute: 'is-watcher' },
      isWidgetsLoaded: { type: Object, reflect: false },
      employers: { type: Array, reflect: false },
      providers: { type: Array, reflect: false },
      employerIds: { type: Array, reflect: false },
      providerIds: { type: Array, reflect: false },
      currentTenantId: { type: String },
      _startDateTime: { type: String, attribute: false },
      _endDateTime: { type: String, attribute: false },
      _invalid: { type: Boolean, attribute: false },
      _timeframe: { type: String, attribute: false },
      _selectedMobileTimeframe: { type: String, attribute: false },
      _isCustomDateActive: { type: Boolean, attribute: false },
      _timeframeStartDateInvalidMessageId: { type: String, attribute: false },
      _timeframeEndDateInvalidMessageId: { type: String, attribute: false },
      _isTimeframeMobileOpened: { type: Boolean, attribute: false },
      _openedFilters: { type: Array, attribute: false },
      _filterOptionsKit: { type: Array, attribute: false },
      _isAllWidgetsDataLoaded: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      inputLabelStyles,
      labelStyles,
      selectStyles,
      css`
        .filters {
          border-bottom: 1px solid var(--d2l-color-mica);
          column-gap: 1.5rem;
          display: flex;
          margin-bottom: 24px;
          padding-bottom: 24px;
        }

        .other-filters {
          border-left: 2px dotted var(--d2l-color-mica);
          display: flex;
          margin: 4px;
          padding-left: 4px;
        }

        .filter-options {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .filter-type {
          margin: 0 4px;
        }

        .mobile-filter-options {
          display: none;
        }

        d2l-button-subtle {
          border: transparent 2px solid;
          box-sizing: border-box;
          display: inline-block;
          margin: 2px;
        }

        d2l-button-subtle.active {
          border: #004489 2px solid;
          border-radius: 9px;
        }

        .timeframe-date-range {
          border: 1px solid #d0d8df;
          border-radius: 5px;
          display: none;
          margin-top: 5px;
          padding: 30px;
        }

        .timeframe-date-range d2l-input-date {
          margin-right: 1.5rem;
        }

        .timeframe-date-range d2l-button {
          margin-right: 0.7rem;
        }

        .timeframe-date-range.active {
          align-items: end;
          display: flex;
          margin-top: 18px;
        }

        .timeframe-dialog-content {
          display: flex;
        }

        @media (max-width: 767px) {
          .filters {
            border-bottom: none;
            margin-bottom: 6px;
          }

          .other-filters {
            border: none;
            padding-left: 0;
          }

          .timeframe-date-range {
            border: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .mobile-date-range-container {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0;
            padding-top: 0;
          }

          .mobile-date-range-container.active {
            border: #004489 2px solid;
            border-radius: 9px;
          }

          .error-tooltip {
            z-index: 1;
          }

          .timeframe-date-range.validation-error {
            position: relative;
          }

          .timeframe-date-range d2l-input-date {
            margin: 0.5rem;
          }

          .timeframe-date-range d2l-button {
            margin: 0;
          }

          .filter-options {
            display: none;
          }

          .mobile-filter-options {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
          }

          .mobile-filter-options .d2l-label-text {
            font-size: 0.7rem;
            font-weight: 700;
            letter-spacing: 0.2px;
            line-height: 1rem;
            margin-right: 10px;
            white-space: nowrap;
          }

          .mobile-filter-options > d2l-button-subtle {
            border: none;
            margin-bottom: 2px;
          }

          .mobile-filter-options > d2l-button-subtle.active {
            border: none;
          }

          .timeframe-dialog-content {
            align-items: center;
            flex-direction: column;
          }

          d2l-button-subtle {
            display: flex;
            margin-bottom: 5px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    // This is a temporary fix before implementing analytics filtering
    this._startDateTime = '2021-01-01T00:00:00.000Z';
    this._endDateTime = new Date().toISOString();
    this._invalid = true;
    this.employers = [];
    this.providers = [];
    this.employerIds = [];
    this.providerIds = [];
    this.isWidgetsLoaded = {};
    this._timeframe = 'lastMonth';
    this._isCustomDateActive = false;
    this._timeframeStartDateInvalidMessageId = '';
    this._timeframeEndDateInvalidMessageId = '';
    this._isTimeframeMobileOpened = false;
    this._openedFilters = [];
    this._timeframeFilterParams = [
      { id: 'lastWeek' },
      { id: 'lastMonth' },
      { id: 'lastThreeMonths' },
    ];
    this._filterIdPrefix = 'filter-';
    this._filterDimensionKeys = [
      'provider',
    ];
    // Form string with all filter ids to use with applied-filter component
    this._filterIdsString = this._filterIdPrefix + this._filterDimensionKeys.join(this._filterIdPrefix);
    this._isAllWidgetsDataLoaded = false;
    this._customDateChanged = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this.filter = new ReportDashboardFilterModel(this.session.user.getSetting('analytics-filters'));

    if (this.isProvider || this.isAdmin) {
      this._timeframeFilterParams.push({
        id: 'lastYear',
      });
    } else {
      this._timeframeFilterParams.push({
        id: 'sinceFiscalYear',
      });
    }
  }

  async firstUpdated() {
    if (this.isProvider) {
      this.filter.provider = this.providerIds;
    } else if (!this.isAdmin) {
      // watcher or sponsor
      this.filter.employer = this.employerIds;
    }
    if (this.session.tenant.hasTag('ownRequestSubmit')) {
      this.providerIds.push(SUBMIT_MY_OWN_PROVIDER_ID);
      this.providers.push(new Tenant({ id: SUBMIT_MY_OWN_PROVIDER_ID, name: this.localize('application-table.filter.provider.smo') }));
    }
    // If this tenant uses test data then we want to show the custom date range by default
    if (this.session.tenant.hasTag('testData')) {
      this._startDateTime = '2020-01-01';
      this._endDateTime = new Date().toISOString();
      this._timeframe = '';
    } else {
      this._startDateTime = '';
      this._endDateTime = '';
    }

    this._filterChanged();
  }

  updated(changedProperties) {
    if (changedProperties.has('isWidgetsLoaded')) {
      this._isAllWidgetsDataLoaded = this.isWidgetsLoaded ? !Object.values(this.isWidgetsLoaded).includes(false) : false;
    }
  }

  render() {

    return html`
      <div class="filters">
        <div class="filter-options">
          ${this._filterOptions}
        </div>
        ${this._mobileFilterOptions}
      </div>
    `;
  }

  get _filterOptions() {
    const isCustomDateRangeActive = '' === this._timeframe;

    const timeframeButtonClasses = tfp => {
      return {
        'active': tfp.id === this._timeframe,
      };
    };
    const customTimeframeButtonClasses = {
      'active': isCustomDateRangeActive,
    };

    return html`
      <div class="timeframe-dialog-content">
        ${repeat(this._timeframeFilterParams, tfp => tfp.id, tfp => html`
          <d2l-button-subtle
            class=${classMap(timeframeButtonClasses(tfp))}
            @click=${this._timeframeChanged(tfp.id)}
            text=${this.localize(`view-report-dashboard.timeframeFilter.${tfp.id}`)}
            ?disabled=${!this._isAllWidgetsDataLoaded}
          ></d2l-button-subtle>`)}

          <d2l-button-subtle
            class=${classMap(customTimeframeButtonClasses)}
            text=${`${this.localize('view-report-dashboard.timeframeFilter.custom')} ${isCustomDateRangeActive ? '(1)' : ''}`}
            icon="tier1:chevron-down"
            @click=${this._openCloseCustomDateRange}
            icon-right
            ?disabled=${!this._isAllWidgetsDataLoaded}
          ></d2l-button-subtle>
          ${this._otherFiltersCollectionTemplate}
        </div>
        ${this._timeframeDateRange}
        ${this._selectedFilterOptions}
    `;
  }

  get _mobileFilterOptions() {
    return html`
      <div class="mobile-filter-options">
        <p class='d2l-label-text'>${this.localize('view-report-dashboard.filterBy')}: </p>
        <d2l-button-subtle
          slot="text"
          icon="tier1:chevron-down"
          text="${this.localize('view-report-dashboard.timeframeFilter.timeframeFilterButton')} (1)"
          icon-right
          type="button"
          @click=${this._openTimeframeFilterDialog}
          ?disabled=${!this._isAllWidgetsDataLoaded}>
        </d2l-button-subtle>
        ${this._otherFilterTemplateMobile}
        <d2l-dialog-fullscreen
          id="timeframeDialog"
          ?opened=${this._isTimeframeMobileOpened}
          @d2l-dialog-close=${this._closeTimeframeFilterDialog}
          title-text="${this.localize('view-report-dashboard.timeframeFilter.timeframeFilterButton')}"
        >
          ${this._timeframeMobileFilterOptions}
          <d2l-button slot="footer" primary @click=${this._saveTimeframeMobileFilter}>
            ${this.localize('view-report-dashboard.timeframeFilter.applyButton')}
          </d2l-button>
          <d2l-button slot="footer" @click=${this._setDefaultFilter}>${this.localize('view-report-dashboard.timeframeFilter.clearButton')}</d2l-button>
        </d2l-dialog-fullscreen>
      </div>
    `;
  }

  get _otherFilterCollectionMobileTemplate() {
    const renderElements = [];

    if (!this.isProvider || this.isAdmin) {
      renderElements.push(this._filterSetDimensionTemplate('provider', this.providers, 'general.provider'));
    }

    if (this.isProvider || this.isAdmin) {
      renderElements.push(this._filterSetDimensionTemplate('employer', this.employers, 'general.employer'));
    }

    return renderElements.length ? html`
      ${renderElements}
    ` : nothing;
  }

  get _otherFiltersCollectionTemplate() {
    const renderElements = [];

    if (!this.isProvider || this.isAdmin) {
      renderElements.push(this._otherFilterTemplate('provider', this.providers, 'general.provider'));
    }

    if (this.isProvider || this.isAdmin) {
      renderElements.push(this._otherFilterTemplate('employer', this.employers, 'general.employer'));
    }

    return renderElements.length ? html`
      <div class="other-filters">
        ${renderElements}
      </div>
    ` : nothing;
  }

  get _otherFilterTemplateMobile() {
    return html`
      <div class="other-filters d2l-skeletize">
        <d2l-filter
          id="other-filters"
          text="${this.localize('view-report-dashboard.mobile.otherFilters')}"
          @d2l-filter-change=${this._dimensionValueChanged}
          @d2l-filter-dimension-first-open="${this._handleFilterFirstOpened}"
          ?disabled=${!this._isAllWidgetsDataLoaded}
          >
          ${this._otherFilterCollectionMobileTemplate}
        </d2l-filter>
      </div>`;
  }

  get _selectedFilterOptions() {
    this._filterOptionsKit = [];

    const providersOptions = [];
    const employersOptions = [];
    const isProviderOrAdmin = this.isProvider || this.isAdmin;
    const isEmployerOrAdmin = !this.isProvider || this.isAdmin;

    this.filter.filterOptions.forEach(option => {
      if (option.filterType === 'provider' && this.providers.length) {
        const updatedOption = option;

        updatedOption.name = this.providers.find(provider => provider.id === option.id)?.name || '';

        if (updatedOption && isEmployerOrAdmin) {
          providersOptions.push(updatedOption);
        }
      }

      if (option.filterType === 'employer' && this.employers.length) {
        const updatedOption = option;

        updatedOption.name = this.employers.find(employer => employer.id === option.id)?.name || '';

        if (updatedOption && isProviderOrAdmin) {
          employersOptions.push(updatedOption);
        }
      }
    });

    this._filterOptionsKit = [...this._sortOptionsByName(providersOptions), ...this._sortOptionsByName(employersOptions)];

    return html`
      <report-applied-filter-options
        timeframe-text-value=${this._getSelectedTimeframeValue()}
        .options=${this._filterOptionsKit}
        @d2l-remove-option=${this._dimensionValueChanged}
      ></report-applied-filter-options>
    `;
  }

  get _timeframeDateRange() {
    const timeframeDateRangeClasses = {
      'timeframe-date-range': true,
      'active': this._isCustomDateActive,
    };

    // the min date for the calendar selector is being set to 10 years in the past and is formatted as YYYY-MM-DD
    const now = new Date();
    const minYear = now.getFullYear() - 10;
    const minMonth = (now.getMonth() + 1).toString().padStart(2, '0');
    let minDayOfMonth = (now.getDate()).toString().padStart(2, '0');
    if (minMonth === '02' && minDayOfMonth === '29') {
      minDayOfMonth = '28';
    }
    const minDate = `${minYear}-${minMonth}-${minDayOfMonth}`;

    return html`
    <div class=${classMap(timeframeDateRangeClasses)} >
      <d2l-input-date
        id="start-date"
        label=${`${this.localize('view-report-dashboard.timeframeFilter.startDate') } *`}
        .forceInvalid=${!!this._timeframeStartDateInvalidMessageId}
        .value="${this._startDateTime}"
        @change=${this._timeframeStartDateChanged}
        @blur=${this._isValidCustomStartDate}
        min-value=${minDate}
      >
      </d2l-input-date>
      <d2l-input-date
        id="end-date"
        label=${`${this.localize('view-report-dashboard.timeframeFilter.endDate') } *`}
        .forceInvalid=${!!this._timeframeEndDateInvalidMessageId}
        .value="${this._endDateTime}"
        @change=${this._timeframeEndDateChanged}
        @blur=${this._isValidCustomEndDate}
      >
      </d2l-input-date>
      ${this._timeframeStartDateInvalidMessageId ? html`
        <d2l-tooltip align="start" state="error" for="start-date">
          ${this.localize(this._timeframeStartDateInvalidMessageId)}
        </d2l-tooltip>
      ` : nothing }
      ${this._timeframeEndDateInvalidMessageId ? html`
        <d2l-tooltip align="start" state="error" for="end-date">
          ${this.localize(this._timeframeEndDateInvalidMessageId)}
        </d2l-tooltip>
      ` : nothing }
      <d2l-button @click=${this._applyFilter} primary>${this.localize('view-report-dashboard.timeframeFilter.applyButton')}</d2l-button>
      <d2l-button @click=${this._setDefaultFilter}>${this.localize('view-report-dashboard.timeframeFilter.clearButton')}</d2l-button>
    </div>
    `;
  }

  get _timeframeMobileDateRange() {
    const timeframeDateRangeClasses = {
      'timeframe-date-range': true,
      'validation-error': this._timeframeStartDateInvalidMessageId || this._timeframeEndDateInvalidMessageId,
    };
    const dateRangeContainerClasses = {
      'mobile-date-range-container': true,
      'active': !this._selectedMobileTimeframe,
    };
    return html`
    <div class=${classMap(timeframeDateRangeClasses)}>
      <div class=${classMap(dateRangeContainerClasses)}>
        <d2l-input-date
          id="mobile-start-date"
          label=${`${this.localize('view-report-dashboard.timeframeFilter.startDate') } *`}
          .forceInvalid=${!!this._timeframeStartDateInvalidMessageId}
          .value="${this._startDateTime}"
          @change=${this._timeframeStartDateChanged}
          @blur=${this._isValidCustomStartDate}
          @focus=${this._timeframeMobileChanged('')}
        >
        </d2l-input-date>
        <d2l-input-date
          id="mobile-end-date"
          label=${`${this.localize('view-report-dashboard.timeframeFilter.endDate') } *`}
          .forceInvalid=${!!this._timeframeEndDateInvalidMessageId}
          .value="${this._endDateTime}"
          @change=${this._timeframeEndDateChanged}
          @blur=${this._isValidCustomEndDate}
          @focus=${this._timeframeMobileChanged('')}
        >
        </d2l-input-date>
        ${this._timeframeStartDateInvalidMessageId ? html`
          <d2l-tooltip
            align="start"
            state="error"
            for="mobile-start-date"
            class="error-tooltip"
          >
            ${this.localize(this._timeframeStartDateInvalidMessageId)}
          </d2l-tooltip>
        ` : nothing }
        ${this._timeframeEndDateInvalidMessageId ? html`
          <d2l-tooltip
            align="start"
            state="error"
            for="mobile-end-date"
            class="error-tooltip"
          >
            ${this.localize(this._timeframeEndDateInvalidMessageId)}
          </d2l-tooltip>
        ` : nothing }
      </div>
    </div>
    `;
  }

  get _timeframeMobileFilterOptions() {
    const timeframeFilterClasses = tfp => {
      return {
        'active': tfp.id === this._selectedMobileTimeframe,
      };
    };
    return html`
      <div class="timeframe-dialog-content">
        ${repeat(this._timeframeFilterParams, tfp => tfp.id, tfp => html`
          <d2l-button-subtle
            class=${classMap(timeframeFilterClasses(tfp))}
            @click=${this._timeframeMobileChanged(tfp.id)}
            text=${this.localize(`view-report-dashboard.timeframeFilter.${tfp.id}`)}
          ></d2l-button-subtle>
        `)}
      </div>
      ${this._timeframeMobileDateRange}
    `;
  }

  _applyFilter() {
    if (this._customDateChanged) {

      this._customDateChanged = false;

      const isValidStartDate = this._isValidCustomStartDate();
      const isValidEndDate = this._isValidCustomEndDate();

      if (!isValidStartDate || !isValidEndDate) return;

      this._timeframe = '';
      this._loadingStatusChange();
      this._filterChanged();
    }

    this._openCloseCustomDateRange();
  }

  _closeTimeframeFilterDialog() {
    this._isTimeframeMobileOpened = false;
    this._timeframeStartDateInvalidMessageId = '';
    this._timeframeEndDateInvalidMessageId = '';
  }

  _dateFormat(dateString) {
    return formatIsoDate(dateString, false, 'short');
  }

  _dimensionValueChanged(e) {
    e.stopPropagation();

    e.detail.dimensions.forEach(dimension => {
      const dimensionKey = dimension.dimensionKey;

      if (dimension.cleared) {
        this.filter[dimensionKey].length = 0;
      } else {
        const changedValues = dimension.changes;
        if (changedValues[0].selected) {
          if (this.filter[dimensionKey]?.length > 0) {
            this.filter[dimensionKey].push(changedValues[0].valueKey);
          } else {
            this.filter[dimensionKey] = [changedValues[0].valueKey];
          }
        } else {
          const index = this.filter[dimensionKey].indexOf(changedValues[0].valueKey);
          this.filter[dimensionKey].splice(index, 1);
        }
      }
    });

    this.filter.provider = [...new Set(this.filter.provider)];
    this.filter.employer = [...new Set(this.filter.employer)];

    this._loadingStatusChange();
    this._filterChanged();
    this.client.logEvent({ eventType: 'filterApplied', ...this.filter.eventData });
    this.requestUpdate();
  }

  async _filterChanged() {
    await this.client.setSessionSetting('analytics-filters', this.filter);

    const filterChangedEvent = new CustomEvent('d2l-filter-change', {
      bubbles: true,
      composed: true,
      detail: {
        filter: {
          startDateTime: this._startDateTime,
          endDateTime: this._endDateTime,
          invalid: this._invalid,
          employers: this.employers,
          providers: this.providers,
          selectedEmployers: this.filter.employer.length ? this.filter.employer : this.employerIds,
          selectedProviders: this.filter.provider.length ? this.filter.provider : [],
          timeframe: this._timeframe,
        },
      },
    });

    this.dispatchEvent(filterChangedEvent);
  }

  _filterSetDimensionTemplate(id, mapping, translationKeyBase) {
    const ariaLabel = (id === 'provider' || id === 'employer') ? this.localize(`${translationKeyBase}`) : this.localize(`${translationKeyBase}.ariaLabel`);
    const displayText = (id === 'provider' || id === 'employer') ? this.localize(`${translationKeyBase}`) : this.localize(`${translationKeyBase}.displayText`);

    return html`
      <d2l-filter-dimension-set
        aria-label=${ariaLabel}
        key=${id} text=${displayText}
        search-type="automatic"
        ?loading=${!this._openedFilters.includes(id)}>
        ${this._filterSetValuesTemplate(mapping, id)}
      </d2l-filter-dimension-set>
    `;
  }

  _filterSetValuesTemplate(options, dimensionKey) {
    if (dimensionKey === 'provider' || dimensionKey === 'employer') return this._filterValuesTemplate(options, dimensionKey);

    const optionsToShow = this._getOptionsToShow(options, dimensionKey);

    return repeat(optionsToShow, option => option.displayName,
      option => html`
        <d2l-filter-dimension-set-value
          key=${option.value}
          text=${option.displayName}
          ?selected=${!this._openedFilters.includes(dimensionKey) || this.filter[dimensionKey].includes(option.value)}
          ?disabled=${!this._isAllWidgetsDataLoaded}>
        </d2l-filter-dimension-set-value>
      `
    );
  }

  _filterValuesTemplate(valuesMap, dimensionKey) {
    const valuesToShow = this._sortOptionsByName(this._getOptionsToShow(valuesMap, dimensionKey, 'id'));

    return repeat(valuesToShow, value => value.id,
      value => html`
        <d2l-filter-dimension-set-value
          key=${value.id}
          text=${value.name}
          ?selected=${this.filter[dimensionKey].includes(value.id)}
          ?disabled=${!this._isAllWidgetsDataLoaded}>
        </d2l-filter-dimension-set-value>
      `
    );
  }

  _getOptionsToShow(options, dimensionKey, filterPropertyName = 'value') {
    if (this._openedFilters.includes(dimensionKey)) return options;
    if (this.filter[dimensionKey]?.length > 0) {
      return options.filter(option => this.filter[dimensionKey].includes(option[filterPropertyName]));
    }
    return [];
  }

  _getSelectedTimeframeValue() {
    if (this._timeframe === '') {
      const fromWord = this.localize('view-report-dashboard.timeframeFilter.from');
      const toWord = this.localize('view-report-dashboard.timeframeFilter.to');

      return `${fromWord} ${this._dateFormat(this._startDateTime)} ${toWord} ${this._dateFormat(this._endDateTime)}`;
    }

    const timeframeId = this._timeframeFilterParams.find(parameter => parameter.id === this._timeframe).id;

    const textFilterValue = this.localize(`view-report-dashboard.timeframeFilter.${timeframeId}`);

    const lastWord = this.localize('view-report-dashboard.timeframeFilter.last');

    if (this._timeframe === 'lastMonth' || this._timeframe === 'lastThreeMonths') return `${lastWord} ${textFilterValue}`;

    return textFilterValue;
  }

  _handleFilterFirstOpened(e) {
    const dimensionKey = e.detail.key;
    this._openedFilters.push(dimensionKey);
    this.requestUpdate();
  }

  _isValidCustomDateRange() {
    const startDate = this._startDateTime;
    const endDate = this._endDateTime;

    if (startDate >= endDate) {
      this._timeframeStartDateInvalidMessageId = 'view-report-dashboard.timeframeFilter.invalidDateRange';
      this._timeframeEndDateInvalidMessageId = 'view-report-dashboard.timeframeFilter.invalidDateRange';

      return false;
    }

    this._timeframeStartDateInvalidMessageId = '';
    this._timeframeEndDateInvalidMessageId = '';

    return true;
  }

  _isValidCustomEndDate() {
    if (!this._endDateTime) {
      this._timeframeEndDateInvalidMessageId = 'view-report-dashboard.timeframeFilter.requiredFieldMessage';
      return false;
    }

    if (this._startDateTime) {
      return this._isValidCustomDateRange();
    }

    this._timeframeEndDateInvalidMessageId = '';
    return true;
  }

  _isValidCustomStartDate() {
    if (!this._startDateTime) {
      this._timeframeStartDateInvalidMessageId = 'view-report-dashboard.timeframeFilter.requiredFieldMessage';
      return false;
    }

    if (this._endDateTime) {
      return this._isValidCustomDateRange();
    }

    this._timeframeStartDateInvalidMessageId = '';
    return true;
  }

  async _loadingStatusChange() {
    const loadingStatusChangeEvent = new CustomEvent('loading-status-change', {
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(loadingStatusChangeEvent);
  }

  _openCloseCustomDateRange() {
    if (!this._isCustomDateActive) {
      this._timeframeStartDateInvalidMessageId = '';
      this._timeframeEndDateInvalidMessageId = '';
    }

    this._isCustomDateActive = !this._isCustomDateActive;
  }

  _openTimeframeFilterDialog() {
    this._isTimeframeMobileOpened = true;
    this._selectedMobileTimeframe = this._timeframe;
  }

  _otherFilterTemplate(id, mapping, translationKeyBase) {
    return html`
      <div class="d2l-skeletize filter-type">
        <d2l-filter
          id="${this._filterIdPrefix}${id}"
          dimension-key="${this._filterIdPrefix}${id}"
          @d2l-filter-change=${this._dimensionValueChanged}
          @d2l-filter-dimension-first-open=${this._handleFilterFirstOpened}
          ?disabled=${!this._isAllWidgetsDataLoaded}>
          ${this._filterSetDimensionTemplate(id, mapping, translationKeyBase)}
        </d2l-filter>
      </div>
    `;
  }

  _saveTimeframeMobileFilter() {

    if (!this._selectedMobileTimeframe) {
      if (!this._isValidCustomStartDate() || !this._isValidCustomEndDate()) return;
    }

    this._timeframe = this._selectedMobileTimeframe;

    this._loadingStatusChange();
    this._filterChanged();

    this._closeTimeframeFilterDialog();
  }

  _setDefaultFilter() {
    this._timeframeStartDateInvalidMessageId = '';
    this._timeframeEndDateInvalidMessageId = '';
    this._timeframe = 'lastMonth';
    this._selectedMobileTimeframe = 'lastMonth';
    this._startDateTime = '';
    this._endDateTime = '';
    this._openCloseCustomDateRange();
    this._closeTimeframeFilterDialog();

    this._loadingStatusChange();
    this._filterChanged();
  }

  _sortOptionsByName(options) {
    return options.sort((firstOption, secondOption) => firstOption.name.localeCompare(secondOption.name));
  }

  _timeframeChanged(timeframeValue) {
    return () => {
      if (timeframeValue && this._timeframe === timeframeValue) {
        return;
      }

      if (this._isCustomDateActive) {
        this._openCloseCustomDateRange();
      }

      this._timeframe = timeframeValue;

      this._startDateTime = '';
      this._endDateTime = '';

      this._loadingStatusChange();
      this._filterChanged();
    };
  }

  _timeframeEndDateChanged(e) {
    this._customDateChanged = true;
    if (e.target.value) {
      const dateEnd = new Date(e.target.value);

      dateEnd.setUTCHours(23, 59, 59, 999);

      this._endDateTime = dateEnd.toISOString();
    } else {
      this._endDateTime = e.target.value;
    }

    this._isValidCustomEndDate();
  }

  _timeframeMobileChanged(timeframeValue) {
    return () => {
      if (timeframeValue) {
        this._startDateTime = '';
        this._endDateTime = '';
        this._timeframeStartDateInvalidMessageId = '';
        this._timeframeEndDateInvalidMessageId = '';

        if (this._selectedMobileTimeframe === timeframeValue) {
          return;
        }
      }

      this._selectedMobileTimeframe = timeframeValue;
    };
  }

  _timeframeStartDateChanged(e) {
    this._customDateChanged = true;
    this._startDateTime = e.target.value;
    this._isValidCustomStartDate();
  }
}

window.customElements.define('report-dashboard-filter', ReportDashboardFilter);
